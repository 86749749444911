export const PASS_MIN_LENGTH = 8;
export const emailValidation = (val: string) => /^[a-z,\.,\-,_,\d]+@[a-z]+\.[a-z]{2,}/i.test(val);
export const phoneNumberValidation = (val: string) => /^\+\d[\d\\(\\)\\ -]{4,14}\d$/.test(val);
export const alphaAndDigitValidation = (val: string) => /^(?=.*\d)(?=.*[a-z,A-Z])(?!.*\s)(?!.*[а-яА-ЯЁё]).*$/.test(val);

export const languages = ['ru', 'en', 'es', 'pt'];

export const TabsCommutator = {
  on(watchKey: string, cb: Function) {
    TabsCommutator._cb = (evt: StorageEvent) => {
      const { key, newValue, oldValue, url }: StorageEvent = evt;
      if (key === watchKey && newValue) cb({ key, newValue, oldValue, url });
    }
    window.addEventListener('storage', TabsCommutator._cb);
  },
  emit(key: string, payload: string | boolean) {
    if (key && key.length) {
      localStorage.setItem(key, '');
      localStorage.setItem(key, payload?.toString());
    } else {
      throw new Error(`TabsCommutator.emit method expects 1 argument as String`);
    }
  },
  off(key: string) {
    window.removeEventListener('storage', TabsCommutator._cb);
    localStorage.removeItem(key);
  },
  _cb: (evt: StorageEvent) => {}
}

export const gtmSend = (data: any) => {
  //@ts-ignore
  window.dataLayer.push(data)
}

export const getLocalLanguage = () => {
  const browserLanguageRegion = window.navigator.language;
  const browserLanguage = browserLanguageRegion.substring(0, 2).toLowerCase();
  if(languages.includes(browserLanguage)) {
    return browserLanguage
  } else {
    return browserLanguage;
  } 
}